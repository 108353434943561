<template>
    <div class="page-wrapper">
        <section class="hero_empty"></section>

        <section class="houses_wrap hero_position" v-if="pageIsLoaded">
            <div class="title_wrap">
                <img
                    src="@/assets/img/icon-deux-maisons.svg"
                    alt="Icon maisons péladeau"
                    data-inview="fadeInUp"
                    data-delay="200"
                />
                <h3 class="title medium" data-inview="fadeInUp" data-delay="300">
                    Deux centres pour vous accueillir
                </h3>
            </div>
            <router-link
                :to="item.page.jsonUrl"
                class="house"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in globals.centresEntry.data" :key="i"
            >
                <div class="img">
                    <img
                        :src="item.page.imageSimple.url"
                        :alt="item.page.imageSimple.titre"
                    />
                </div>
                <div class="data">
                    <p class="regular-text">{{ item.page.titre1 }}</p>
                    <h4 class="title small">{{ item.page.titre }}</h4>
                    <svg
                        fill="none"
                        class="plus"
                        viewBox="0 0 60 60"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="30" cy="30" r="30" />
                        <path
                            d="m30 20c-.7268 0-1.3158.589-1.3158 1.3158v7.3684h-7.3684c-.7268 0-1.3158.589-1.3158 1.3158s.589 1.3158 1.3158 1.3158h7.3684v7.3684c0 .7268.589 1.3158 1.3158 1.3158s1.3158-.589 1.3158-1.3158v-7.3684h7.3684c.7268 0 1.3158-.589 1.3158-1.3158s-.589-1.3158-1.3158-1.3158h-7.3684v-7.3684c0-.7268-.589-1.3158-1.3158-1.3158z"
                        />
                    </svg>
                </div>
            </router-link>
        </section>

        <section class="text_img" v-if="pageIsLoaded">
            <div class="data">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    <span v-html="content.blockInformations1.titre"></span>
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.blockInformations1.texte"></span>
                </p>
                <a :href="content.blockInformations1.boutonUrl[$route.meta.lang]" class="cta regular-text" data-inview="fadeInUp" data-delay="400"
                    >{{ content.blockInformations1.bouton.customText }}</a
                >
            </div>
            <div class="img" data-inview="slideLeft" data-delay="200">
                <img :src="content.blockInformations1.image.url" :alt="content.blockInformations1.image.titre" />
                <img src="@/assets/img/birds.svg" alt="birds" class="birds" />
            </div>
            <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
        </section>

        <section
            class="text_img" :class="i % 2 ? '' : 'switch'"
            v-for="(item, i) in splitImageTexte"
            :key="i"
        >
            <div class="data">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    <span v-html="item.titre"></span>
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="item.texte"></span>
                </p>
                <a
                    :href="item.boutonUrl[$route.meta.lang]"
                    target="_blank"
                    class="cta regular-text"
                    data-inview="fadeInUp"
                    data-delay="400"
                    >{{ item.bouton.customText }}</a
                >
            </div>
            <div class="img" :data-inview="i % 2 ? 'slideRight' : 'slideLeft'" data-delay="200">
                <img :src="item.image.url" :alt="item.image.titre" />
            </div>
            <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'
// import Flickity from 'flickity'

export default {
    name: 'Centres',

    data() {
        return {
            // teamCarousel: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                        //     wrapAround: true,
                        //     imagesLoaded: true,
                        //     prevNextButtons: true,
                        //     pageDots: false,
                        // })
                    }, 1055)

                    this.toogleNavBarStatus()
                    window.addEventListener('scroll', () => {
                        this.toogleNavBarStatus()
                    })
                }
            },
            immediate: true,
        },
    },

    computed: {
        splitImageTexte() {
            if (!this.contentIsLoaded) {
                return []
            }
            if (!this.content.splitImageTexte) {
                return []
            }
            return this.content.splitImageTexte
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero_empty')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero_empty').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero_empty').classList.remove('remove_margin_top')
            }
        },
    },
}
</script>
